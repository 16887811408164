.chat_button {
  border-radius: 50%;
  border-width: 0px;
  border-color: none;
  width: 40px;
  height: 40px;
  /* background-color: #490E6F ; */
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 5px 8px 17px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 5px 8px 17px 0px rgba(0, 0, 0, 0.28);
  position: inherit;
  bottom: inherit;
  right: inherit;
  left: inherit;
  top: inherit;
  /* border-color: #490E6F; */
  border-width: 2px;
  visibility: inherit;
}

.renown {
  background-color: #490e6f;
  border-color: #490e6f;
}

.renown:hover{
  opacity: 0.8;
  background: radial-gradient(#f3f3f3, #490e6f);
}

.hth_1969 {
  background-color: #e60d2e;
  border-color: #e60d2e;
}

.hth_1969:hover{
  opacity: 0.8;
  background: radial-gradient(#f3f3f3, #e60d2e);
}

.header_title {
  color: rgb(236, 236, 236);
  font-weight: bold;
  font-size: 10pt;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .chat_button:hover {
  opacity: 0.8;
  background: radial-gradient(#f3f3f3, #490e6f);
}

.chat_button:active {
  opacity: 0.5;
} */

.chat_icon {
  fill: 'white';
}

.chat_container {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 40px;
  right: 0px;
  z-index: 20;
  background-color: rgb(189, 189, 189);
  height: 400px;
  width: 280px;
  border-radius: 5px;
  overflow: none;
  -webkit-box-shadow: 5px 5px 13px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 13px 1px rgba(0, 0, 0, 0.25);
  visibility: visible;
}

.renown_header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #490e6f;
  height: 40px;
  min-height: 40px;
  border-radius: 5px 5px 0px 0px;
}

.hth_header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e60d2e;
  height: 40px;
  min-height: 40px;
  border-radius: 5px 5px 0px 0px;
}

.chat_container .client_input {
  flex-grow: 1;
}

.chat_container .message_container {
  flex-grow: 1;
  background-color: white;
  overflow-y: scroll;
}

.message_container ul {
  padding-left: 0px;
}

.message_container {
  display: flex;
  flex-direction: column-reverse;
}

.chat_container .client_message {
  list-style: none;
  background-color: #b8b8b4;
  color: #222222;
  border-radius: 5px;
  font-size: 10pt;
  margin-right: 5px;
  margin-left: 45px;
  margin-top: 15px;
  padding: 3px;
  text-align: left;
  padding-left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.client_file_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.client_file_button img {
    width: 20px;
    height: 20px;
}

.client_file_button{
    border-radius: 50%;
    background-color: #b8b8b4;
    border-width: 0;
    border-style: none;
    /* width: 15px;
    height: 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding:2px;
}

.client_file_button:hover{
    background-color: #d8d8d4;
}

.client_file_button:active{
    background-color: #8b8b88;
}

.connection_error_header {
  text-align: center;
  color: grey;
  font-size: 13pt;
  margin-bottom: 5px;
  padding-bottom: 2px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.connection_error_subheader {
  text-align: center;
  color: grey;
  font-size: 10pt;
  font-weight: normal;
  padding: 3px;
  margin: 3px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_picker {
  background-color: rgb(194, 194, 194);
  border-radius: 10px;
  padding: 2px;
  padding-bottom: 6px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_picker_title {
  text-align: center;
  color: rgb(34, 34, 34);
  font-size: 13pt;
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_picker_subtitle {
  text-align: center;
  color: rgb(71, 71, 71);
  margin-top: 4px;
  font-size: small;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_picker_options_container {
  display: flex;
  flex-direction: column;
}

.list_picker_option {
  border-width: 0;
  border-radius: 2px;
  margin: 3px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  -webkit-box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.22);
}

.list_picker_option:hover {
  opacity: 0.5;
}

.list_picker_option:active {
  opacity: 0.25;
}

.client_message p {
  padding: 0px;
  margin: 2px;
}



.chat_container .agent_message {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 10pt;
  /* margin-right: 5px; */
  margin-left: 5px;
  margin-right: 25px;
  margin-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.agent_message_sender {
  padding: 0px;
  margin: 2px;
  color: gray;
  text-align: left;
}

.agent_message_container {
  background-color: #6bd9ec;
  color: #222222;
  border-radius: 5px;
  text-align: left;
  word-wrap: normal;
  padding: 3px;
  max-width: 220px;
  min-width: 50px;
}

.agent_message_text {
  padding: 0px;
  margin: 2px;
  text-align: left;
}


.agent_file_message{
    background-color: #6bd9ec;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    /* margin-right: 5px; */
    margin-left: 5px;
    margin-right: 25px;
    margin-top: 15px;
    color: #222222;
    border-radius: 5px;
    text-align: left;
    word-wrap: normal;
    padding: 3px;
    max-width: 220px;
    min-width: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.agent_file_message p {
    padding: 0px;
    margin: 2px;
  }

.agent_file_button{
    border-radius: 50%;
    background-color: #6bd9ec;
    border-width: 0;
    border-style: none;
    /* width: 15px;
    height: 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding:2px;
}

.agent_file_button:hover{
    background-color: #9deaf8;
}

.agent_file_button:active{
    background-color: #42a0b1;
}

.connecting_message_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.connecting_message {
  font-size: 10pt;
  color: #616161;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chat_ended_messge_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat_ended_title {
  font-size: 10pt;
  text-align: center;
  font-weight: bold;
  color: rgb(148, 148, 148);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chat_ended_subtitle {
  text-align: center;
  font-size: 8pt;
  color: rgb(148, 148, 148);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.send_button {
  border-radius: 50%;
  background-color: white;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  margin: 2px;
}

.send_button:disabled{
    background-color: white;
    opacity: 0.5;
}

.send_button:enabled:hover {
  background-color: #ececec;
} 

/* .send_button:hover:disabled {
  background-color: white;
} */
  /* border-radius: none; */
/* } */ 


.chat_container * .client_input {
  border-color: none;
  border-width: 0px;
  padding: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 0px;
  padding-left: 10px;
  outline: none;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.endchat_button {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 10px; */
  border-width: none;
  border-style: none;
  border-radius: 0px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgb(216, 216, 216);
  color: rgb(12, 12, 12);
  font-size: small;
  font-weight: bold;
}

.close_icon {
  height: 15px;
  width: 15px;
}

.endchat_button:hover {
  opacity: 0.8;
}

.endchat_button:active {
  opacity: 0.5;
}

.chat_container .input_container {
  display: flex;
  flex-direction: row;
  /* flex-grow: 0; */

  align-items: center;
  justify-content: space-between;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  background-color: white;
}

.file_selected {
  background-color: rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  max-width: 150px;
  /* width:50%; */
  align-items: center;
  border-radius: 10px;
  padding: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 3px;
  margin-right: 3px;
  /* max-width: 80%; */
  /* max-width: 150px; */
  overflow: hidden;
  white-space: nowrap;
}

.file_selected div {
  /* display: block; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;
  width: 250px;
}

.file_selected div p {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 2px;
  padding: 4px;
  font-size: 8pt;

  color: rgb(85, 85, 85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.file_selected_cancel_button {
  background-color: rgb(150, 150, 150);
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  height: 20px;
  width: 20px;
  margin-right: 2px;
  opacity: 0.75;
}

.file_selected_cancel_button:hover {
  opacity: 1;
}

.file_selected_cancel_button img {
  color: pink;
  height: 15px;
  width: 15px;
  border-width: none;
}

.agent_joined_message{
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: flex-start;
}

.agent_joined_text{
  color: rgb(85, 85, 85);
  font-size: 10pt;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
